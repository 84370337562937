<template>
  <div>
    <div style="display: flex">
      <div style="width: 700px;padding: 0 30px">
        <div class="padding-tb">姓名：{{ item.coachName }} / {{ item.coachPersonageDetails?.sex == 0 ? '女':'男' }}</div>
        <div class="padding-tb">民族：{{ item.coachPersonageDetails?.nationName }}</div>
        <div class="padding-tb">婚姻状态：{{ item.coachPersonageDetails?.isMarriage == 0 ? '未婚':'已婚' }}</div>
        <div class="padding-tb">生日：{{item.coachPersonageDetails?.birthdayType == 0 ? '阴历':'阳历'}} - {{ item.coachPersonageDetails?.birthday }}</div>
        <div class="padding-tb">开户银行：{{ item.coachPersonageDetails?.bankName }}</div>
        <div class="padding-tb">银行卡号：{{ item.coachPersonageDetails?.bankCardNumbers }}</div>
        <div style="display:flex;justify-content: space-between;padding:0 0px 20px 0 ">
          <div>
            <div class="padding-tb">个人电话：{{ item.coachPersonageDetails?.personagePhone }}</div>
            <div class="padding-tb">个人邮箱：{{ item.coachPersonageDetails?.mailbox }}</div>
            <div class="padding-tb">身份证号：{{ item.coachPersonageDetails?.identity }}</div>
          </div>
          <div style="width: 45%">
            <div class="padding-tb">紧急联系人：{{ item.coachPersonageDetails?.emergencyContact }}</div>
            <div class="padding-tb">联系人关系：{{ item.coachPersonageDetails?.emergencyContactRelation }}</div>
            <div class="padding-tb">联系人电话：{{ item.coachPersonageDetails?.emergencyContactPhone }}</div>
          </div>
        </div>

        <div v-for="(education,index) in item.coachEducationList" :key="index">
          <div class="padding-tb" style="display:flex;justify-content: space-between;">
            <div>毕业院校：{{education.schoolName}}</div>
            <div style="width:45%">专业：{{education.major}}</div>
          </div>
          <div class="padding-tb" style="display:flex;justify-content: space-between;">
            <div>学历：{{education.educationRankIdName}}</div>
            <div>毕业时间：{{education.graduateTime}}</div>
            <div>学历类型：{{education.isFullTimeName}}</div>
          </div>
        </div>


        <div class="padding-tb" style="margin-top: 20px">现住地址：{{ item.coachPersonageDetails?.thisAddress }}</div>
        <div class="padding-tb">现住详细地址：{{ item.coachPersonageDetails?.thisDetailedAddress }}</div>
        <div class="padding-tb">户籍地址：{{ item.coachPersonageDetails?.registeredResidenceSite }}</div>
        <div class="padding-tb">户籍详细地址：{{ item.coachPersonageDetails?.registeredResidenceDetailedAddress }}</div>
        <div class="padding-tb">个人简历：{{ item.coachPersonageDetails?.individualResume }}</div>
      </div>
      <div style="width: 700px;padding: 0 30px;text-align: left">
        <div class="padding-tb">ID: {{ item.coachId }}</div>
        <div class="padding-tb" style="width: 50%">工作电话：{{ item.coachPhone }}</div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">所属部门：{{ item.deptName }}</div>
          <div class="padding-tb" style="width: 50%">所属校区：{{ item.campusName != null ? item.campusName + '-' + item.flagName : '' }}</div>
        </div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div style="display: flex;justify-items: center;width: 50%">
            <div class="padding-tb">可见校区：</div>
            <el-cascader size="mini" style="width: 170px"
                        placeholder="点击查看可见校区列表"
                        :options="item.areaNameList"
                        filterable
                        :props="{value: 'id',label: 'name',children: 'children'}"/>
          </div>
          <div style="display: flex;justify-items: center;width: 50%">
            <div class="padding-tb">拥有角色：</div>
            <el-select style="margin: 4px 0;width: 170px" size="mini" multiple collapse-tags placeholder="点击查看角色列表">
              <el-option v-for="item in item.roles" :key="item.name" :value="item.name"/>
            </el-select>
          </div>
        </div>
        <div class="padding-tb" style="margin-top: 30px">工号：{{ item.workNumber }}</div>
        <div class="padding-tb" style="margin-top: 30px">合同类型：{{ item.coachRoster.contractTypeName }}</div>
        <div class="padding-tb" style="width: 50%">员工状态：{{ item.coachRoster?.staffTypeName }}</div>
        <div v-if="item.coachOfficialApply" style="margin:10px 0">
        <span> 转正申请时间：{{ item?.coachOfficialApply.createTime }}</span>
        <span> 转正时间：{{ item?.coachOfficialApply.officialDate }}</span>
        </div>
        <div v-if="item.coachDimissionApply" style="margin:10px 0">
          <span> 离职申请时间：{{ item?.coachDimissionApply.createTime }}</span>
          <span> 离职时间：{{ item?.coachDimissionApply.dimissionDate }}</span>
          <div style="margin:10px 0">
            离职原因：{{  item?.coachDimissionApply.dimissionCause }}
          </div>
        </div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">工龄：{{ item.coachRoster?.yearsOfWorking }}</div>
          <div class="padding-tb" style="width: 50%">司龄：{{ item.coachRoster?.companyAge }}</div>
        </div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">首次工作日期：{{ item.coachRoster?.theFirstWorkDate }}</div>
          <div class="padding-tb" style="width: 50%">入职日期：{{ item.coachRoster?.entryDate }}</div>
        </div>
        <div class="padding-tb">工作地点：{{ item.coachRoster?.workplaceName }}</div>
        <el-button  style="margin-top: 30px" @click='searchContarct'>查看合同列表</el-button>
        <div class="padding-tb" >合同签署主体：{{ item.coachRoster?.signContractFirstPartyName }}</div>
        <div style="display:flex;justify-content: space-between;padding:0 20px 0 0 ">
          <div class="padding-tb" style="width: 50%">合同开始日期：{{ item.coachRoster?.contractStartTime }}</div>
          <div class="padding-tb" style="width: 50%">合同结束日期：{{ item.coachRoster?.contractEndTime }}</div>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="合同列表" v-model="listDialog">
    <el-table :data="List" border>
      <el-table-column prop="id" label="Id"/>
      <el-table-column prop="signContractFirstPartyName" label="劳务合同主体"/>
      <el-table-column prop="contractTypeName" label="合同类型"/>
      <el-table-column prop="contractStartTime" label="合同开始时间"/>
      <el-table-column prop="contractEndTime" label="合同结束时间"/>
      <el-table-column prop="contractNumTypeName" label="合同当前状态">
        <template #default="scope">
          <el-tag :type="scope.row.contractNumType == 1 ? '': 'danger'">
            {{scope.row.contractNumTypeName}}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {selectCoachContractByCoachId} from '@/api/coach.js'
export default {
  name: "CoachDetail",
  props: {
    item: String,
  },
  data(){ 
    return{ 
      listDialog:false,
      List:[]
    }
  },
  methods:{
    searchContarct(){ 
      selectCoachContractByCoachId(this.item.coachId).then(res=>{ 
        if(res.code == 200){ 
          this.listDialog = true
          this.List = res.data
        }else{ 
          this.$message.error('获取合同列表失败！')
        }
      })
    }
  }
}
</script>

<style scoped>
.padding-tb{
  padding:5px 0;
}
</style>