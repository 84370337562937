<template>
  <div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="教练信息" name="first">
        <el-form v-loading="editLogin" ref="firstAddRules" :rules="firstAddRules" :model="updateItem"
                 style="padding: 20px 40px 20px 20px" label-width="90px">
          <el-row>
            <el-col :span="6">
              <el-form-item prop="coachName" label="教练姓名">
                <el-input v-model="updateItem.coachName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="coachPhone" label="工作电话">
                <el-input v-model="updateItem.coachPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="workNumber" label="工号">
                <div style="display:flex;">
                  <el-input v-model="updateItem.workNumber"></el-input>
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="点击生成新工号！"
                      placement="top-start"
                  >
                    <div @click="addWorkNumber" class="addWorkNumber">+</div>
                  </el-tooltip>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="教练类型" label-width="110px">
                <el-select placeholder="选择类型" @change="changeStudentType" v-model="orderType">
                  <div v-if="sysStudentType==3">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="中招体育" :value=1></el-option>
                    <el-option label="少年" :value=2></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                  <div v-if="sysStudentType!=3">
                    <el-option v-if="sysStudentType==1" label="中招体育" :value="1"></el-option>
                    <el-option v-else label="少年" :value=2></el-option>
                    <el-option label="高考体育" :value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="deptId" label="所属部门" >
                <el-cascader
                    v-model="updateItem.deptId"
                    placeholder="请选择部门"
                    :props="{
                          value: 'id',
                          label: 'name',
                          children: 'children',
                          checkStrictly: true}"
                    :options="deptList"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="可视部门">
                <el-cascader
                    v-model="updateItem.lookDeptIDList"
                    placeholder="请选择部门"
                    :options="deptList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    clearable
                    :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="campusId" label="所属校区">
                <el-cascader
                    v-model="updateItem.campusId"
                    placeholder="请选择校区"
                    :options="treeList"
                    filterable
                    :props="{value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="管辖校区">
                <el-cascader
                    v-model="updateItem.adminAreaList"
                    placeholder="请选择区域"
                    :options="treeList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    clearable
                    :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="areaList" label="可视区域">
                <el-cascader
                    v-model="updateItem.areaList"
                    placeholder="请选择区域"
                    :options="treeList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    clearable
                    :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="coachRoster.staffType" label="工作类型">
                <el-select
                    @change="selectType"
                    v-model="updateItem.coachRoster.staffType"
                >
                  <el-option v-for="(item,index) in staffTypeList" :key="index" :label="item.dictLabel"
                             :value="item.dictValue*1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="coachRoster.entryDate" label="入职时间">
                <el-date-picker
                    style="width:190px"
                    @blur="getBirthDate('entryDate')"
                    v-model="updateItem.coachRoster.entryDate"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择入职时间"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="coachRoster.businessType" label="业务类型">
                <el-select v-model="updateItem.coachRoster.businessType" class="mr10"
                           placeholder="请选择业务类型">
                  <el-option v-for="item in businessTypeList" :value="item.dictValue*1" :key="item.id"
                             :label="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="coachRoster.theFirstWorkDate" label="首次工作日期" label-width="110px">
                <el-date-picker
                    @blur="getBirthDate('theFirstWorkDate')"
                    style="width:170px"
                    placeholder="请填写"
                    v-model="updateItem.coachRoster.theFirstWorkDate"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="coachRoster.workplace" label="工作地点">
                <el-select
                    style="width:490px"
                    v-model="updateItem.coachRoster.workplace">
                  <el-option v-for="item in workplaceList" :value="item.dictValue*1" :key="item.id"
                             :label="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="roleIdList" label="角色">
                <el-select
                    v-model="updateItem.roleIdList"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip
                    placeholder="请选择"
                >
                  <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="background-color: #f5f7fa;padding: 10px 0 0 0;border-radius: 14px;margin-bottom: 10px">
            <div style="margin: 6px 0 10px 16px;font-weight: bold;font-size: 15px">合同信息</div>
            <el-row>
              <el-col :span="6">
                <el-form-item prop="coachRoster.signContractFirstParty" label="签署主体">
                  <el-select
                      v-model="updateItem.coachRoster.signContractFirstParty">
                    <el-option v-for="item in CompanyList" :value="item.dictValue*1" :key="item.dictValue" :label="item.dictLabel"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="coachRoster.contractType" label="合同类型">
                  <el-select v-model="updateItem.coachRoster.contractType">
                    <el-option v-for="(item,i) in contractTypeList" :key="i" :label="item.dictLabel"
                               :value="item.dictValue*1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="coachRoster.contractStartTime" label="开始日期" >
                  <el-date-picker
                      @blur="getBirthDate('contractStartTime')"
                      v-model="updateItem.coachRoster.contractStartTime"
                      type="date"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择日期"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="coachRoster.contractEndTime" label="结束日期">
                  <el-date-picker
                      @blur="getBirthDate('contractEndTime')"
                      v-model="updateItem.coachRoster.contractEndTime"
                      type="date"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      placeholder="请选择日期"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row v-if="updateItem.coachRoster.staffType==1">
            <el-col :span="8">
              <el-form-item label="转正申请时间" label-width="120">
                <el-date-picker type="date"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请填写"
                                v-model="coachOfficialApply.applyDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="转正时间">
                <el-date-picker type="date"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请填写"
                                v-model="coachOfficialApply.officialDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="updateItem.coachRoster.staffType==2">
            <el-col :span="8">
              <el-form-item label="离职申请时间" label-width="120">
                <el-date-picker type="date"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请填写"
                                v-model="coachDimissionApply.applyDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="离职时间">
                <el-date-picker type="date"
                                format="YYYY-MM-DD"
                                value-format="YYYY-MM-DD"
                                placeholder="请填写"
                                v-model="coachDimissionApply.dimissionDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="离职原因">
                <textarea name="" id="" cols="30" rows="5" v-model="coachDimissionApply.dimissionCause"></textarea>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-button type="primary" @click="contractDialog = true">续签合同</el-button>
            </el-col>
          </el-row>
        </el-form>
        <!-- <el-button type="primary" style="padding-bottom:40px" @click="contractDialog = true">续签合同</el-button> -->
      </el-tab-pane>
      <el-tab-pane label="个人信息" name="second">
        <el-form v-loading="editLogin" ref="secondAddRules" :model="updateItem.coachPersonageDetails"
                 style="padding: 20px 40px 20px 20px" label-width="90px">
          <el-row>
            <el-col :span="6">
              <el-form-item prop="nation" label="民族">
                <el-select size="large" class="mr10" v-model="updateItem.coachPersonageDetails.nation"
                           placeholder="请选择民族" @change="aaa">
                  <el-option v-for="item in nationList" :value="item.dictValue*1 " :key="item.id"
                             :label="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="isMarriage" label="婚姻状态">
                <el-select size="large" class="mr10" v-model="updateItem.coachPersonageDetails.isMarriage"
                           placeholder="请选择婚姻状态">
                  <el-option label="已婚" :value="1"></el-option>
                  <el-option label="未婚" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="birthdayType" label="生日类型">
                <el-select size="large" class="mr10" v-model="updateItem.coachPersonageDetails.birthdayType"
                           placeholder="请选择生日类型">
                  <el-option label="阴历" :value="0"></el-option>
                  <el-option label="阳历" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="birthday" label="选择生日">
                <el-date-picker
                    @blur="getBirthDate('birthday')"
                    style="width:190px"
                    v-model="updateItem.coachPersonageDetails.birthday"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="Pick a day"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="bankName" label="开户银行">
                <el-input v-model="updateItem.coachPersonageDetails.bankName" placeholder="请输入开户银行"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="bankCardNumbers" label="银行卡号">
                <el-input v-model="updateItem.coachPersonageDetails.bankCardNumbers" placeholder="请输入银行卡号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="personagePhone" label="个人电话">
                <el-input v-model="updateItem.coachPersonageDetails.personagePhone" placeholder="请输入个人电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="mailbox" label="个人邮箱">
                <el-input v-model="updateItem.coachPersonageDetails.mailbox" placeholder="请输入个人邮箱"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="identity" label="身份证号">
                <el-input v-model="updateItem.coachPersonageDetails.identity" placeholder="请填写身份证号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="emergencyContact" label="紧急联系人" label-width="110px">
                <el-input v-model="updateItem.coachPersonageDetails.emergencyContact" placeholder="请填写紧急联系人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="emergencyContactRelation" label="联系人关系" label-width="110px">
                <el-input v-model="updateItem.coachPersonageDetails.emergencyContactRelation"
                          placeholder="请填写关系"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="emergencyContactPhone" label="联系人电话">
                <el-input v-model="updateItem.coachPersonageDetails.emergencyContactPhone"
                          placeholder="请填写手机号码"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="thisAddress" label="现住地址">
                <el-cascader
                    size="large"
                    :options="pcaTextArr"
                    v-model="updateItem.coachPersonageDetails.thisAddress">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="thisDetailedAddress" label="现住详细地址" label-width="110px">
                <el-input v-model="updateItem.coachPersonageDetails.thisDetailedAddress"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="sex" label="性别">
                <el-select v-model="updateItem.coachPersonageDetails.sex">
                  <el-option label="男" :value="1"></el-option>
                  <el-option label="女" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item prop="registeredResidenceSite" label="户籍地址">
                <el-cascader
                    size="large"
                    :options="pcaTextArr"
                    v-model="updateItem.coachPersonageDetails.registeredResidenceSite">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="registeredResidenceDetailedAddress" label="户籍详细地址" label-width="110px">
                <el-input v-model="updateItem.coachPersonageDetails.registeredResidenceDetailedAddress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item prop="individualResume" label="个人简历">
                <el-input
                    type="textarea"
                    placeholder="请填写个人简历"
                    :rows="4"
                    v-model="updateItem.coachPersonageDetails.individualResume"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="学历信息" name="third">
        <el-form v-for="(item,index) in updateItem.coachEducationList" :key="index" ref="thirdAddRules"
                 style="padding: 20px 40px 20px 20px" label-width="110px" :model="item">
          <h3>第{{ index + 1 }}学历：</h3>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="educationRankId" label="学历信息">
                <el-select size="large" class="mr10" v-model="item.educationRankId" placeholder="请选择学历">
                  <el-option v-for="items in educationRankList" :value="items.dictValue*1 " :key="items.id"
                             :label="items.dictLabel"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="isFullTime" label="是否全日制">
                <el-select size="large" class="mr10" v-model="item.isFullTime" placeholder="请选择">
                  <el-option label="全日制" :value="0"></el-option>
                  <el-option label="非全日制" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="major" label="专业">
                <el-input v-model="item.major" placeholder="请填专业名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item prop="schoolName" label="学校名称">
                <el-input v-model="item.schoolName" placeholder="请填写学校名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="graduateTime" label="毕业时间">
                <el-date-picker
                    v-model="item.graduateTime"
                    @blur="getBirthDate('graduateTime',index)"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="续签合同" v-model="contractDialog" width="1000px">
      <el-form>
        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="8">
            <el-form-item prop="signContractFirstParty" label="合同签署主体" label-width="110px">
              <el-select
                  size="large"
                  style="width:220px"
                  v-model="contract.signContractFirstParty"
              >
                <el-option v-for="item in CompanyList" :value="item.dictValue*1" :key="item.dictValue"
                           :label="item.dictLabel"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="coachRoster.contractType" label="合同类型" label-width="110px">
              <el-select v-model="contract.contractType" style="width:220px">
                <el-option v-for="(item,i) in contractTypeList" :key="i" :label="item.dictLabel"
                           :value="item.dictValue*1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="display:flex;justify-content: space-around;">
          <el-col :span="8">
            <el-form-item prop="contractStartTime" label="合同开始日期" label-width="110px">
              <el-date-picker
                  @blur="getBirthDate('StartTime')"
                  v-model="contract.contractStartTime"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="contractEndTime" label="合同结束日期" label-width="110px">
              <el-date-picker
                  @blur="getBirthDate('EndTime')"
                  v-model="contract.contractEndTime"
                  type="date"
                  format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD"
                  placeholder="请选择日期"
                  size="large"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="contractDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {pcaTextArr} from 'element-china-area-data'
import {listAreaTreeAddCoach} from "@/api/area.js";
import {listXcxDept} from "@/api/dept";
import {selectDictByType} from '@/api/dict'
import {
  roles,
  createWorkNumber
} from "@/api/coach";

import {updateCoachContract} from '@/api/coachRoster'

export default {
  props: {
    editItem: Object
  },
  inject: ['editContract'],
  data() {
    return {
      contractDialog: false,
      coachDimissionApply: {
        dimissionDate: '',
        dimissionCause: ''
      },
      coachOfficialApply: {
        applyDate: '',
        officialDate: ''
      },
      pcaTextArr,
      orderType: 0,
      activeName: 'first',
      sysStudentType: localStorage.getItem('studentType'),
      updateItem: {
        lookDeptIDList:[],
        coachDimissionApply: {
          dimissionDate: '',
          dimissionCause: ''
        },
        coachOfficialApply: {
          applyDate: '',
          officialDate: ''
        },
      },
      contract: {
        contractEndTime: '',
        contractStartTime: '',
        contractType: '',
        signContractFirstParty: '',
        coachId: null,
      },
      treeList: [],
      deptList: [],
      roles: [],
      staffTypeList: [],
      CompanyList: [],
      nationList: [],
      businessTypeList: [],
      workplaceList: [],
      contractTypeList: [],
      educationRankList: [],
      firstAddRules: {
        coachName: [
          {required: true, message: '请输入教练名字', trigger: 'blur'},
        ],
        coachPhone: [
          {required: true, message: '请输入教练电话', trigger: 'blur'},
        ],
        campusId: [
          {required: true, message: '请选择所属校区', trigger: 'blur'},
        ],
        areaList: [
          {required: true, message: '请选择可视区域', trigger: 'change'},
        ],
        roles: [
          {required: true, message: '请选择教练角色', trigger: 'change'}
        ],
        deptId: [
          {required: true, message: '请选择所属部门', trigger: 'change'}
        ],
      },
    }
  },
  created() {
    this.getList()
  },
  watch: {
    editItem: {
      handler: function (newVal) {
        console.log("进入editItem的监听器");
        this.coachDimissionApply = {
          dimissionDate: '',
          dimissionCause: ''
        }
        this.coachOfficialApply = {
          applyDate: '',
          officialDate: ''
        }
        this.updateItem = JSON.parse(JSON.stringify(newVal))
        console.log(this.updateItem);
        if (!this.updateItem.coachEducationList) {
          this.updateItem.coachEducationList = [
            {
              educationRankId: null,
              graduateTime: null,
              isFullTime: null,
              major: '',
              schoolName: ''
            }
          ]
          this.updateItem.coachPersonageDetails = {}
          this.updateItem.coachRoster = {}
        }
        if (this.updateItem.coachDimissionApply != null) {
          this.coachDimissionApply = this.updateItem.coachDimissionApply
        }
        if (this.updateItem.coachOfficialApply != null) {
          this.coachOfficialApply = this.updateItem.coachOfficialApply
        }
        setTimeout(() => {
          this.$refs.firstAddRules.clearValidate()
        }, 0);
      },
      deep: true, // 深度监听
      immediate: true,
    }
  },
  methods: {
    confirm() {
      this.contract.coachId = this.updateItem.coachId
      updateCoachContract(this.contract).then(res => {
        this.contractDialog = false
        if (res.code == 200) {
          this.$message.success('修改成功')
          this.editContract()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addWorkNumber() {
      createWorkNumber().then(res => {
        if (res.code == 200) {
          this.updateItem.workNumber = res.data
        } else {
          this.$message.error('生成工号失败！')
        }
      })
    },
    addConversionType() {
      if (this.updateItem.coachRoster.staffType == 1) {
        if (this.coachOfficialApply.officialDate) {
          this.coachOfficialApply.officialDate = this.dateFormat(this.coachOfficialApply.officialDate)
          if (this.coachOfficialApply.applyDate) {
            this.coachOfficialApply.applyDate = this.dateFormat(this.coachOfficialApply.applyDate)
          }
          this.updateItem.coachOfficialApply = {}
          this.updateItem.coachOfficialApply = this.coachOfficialApply
          this.ConversionType = false
        } else {
          this.$message.error('请填写转正申请时间和转正时间')
          return false
        }
      }
      if (this.updateItem.coachRoster.staffType == 2) {
        if (this.coachDimissionApply.dimissionDate) {
          this.coachDimissionApply.dimissionDate = this.dateFormat(this.coachDimissionApply.dimissionDate)
          if (this.coachDimissionApply.applyDate) {
            this.coachDimissionApply.applyDate = this.dateFormat(this.coachDimissionApply.applyDate)
          }
          this.updateItem.coachDimissionApply = this.coachDimissionApply
          this.ConversionType = false
        } else {
          this.$message.error('请填写离职时间和离职原因')
          return false
        }
      }


    },
    selectType(e) {
      this.ConversionType = true
      if (e == 1) {
        this.ConversionTypeTtile = '转正申请'
      } else {
        this.ConversionTypeTtile = '离职申请'
      }
    },
    changeStudentType() {
      console.log("切换教练诉讼湖类型,重新加载多级区域!!");

      this.updateItem.campusId = null
      listAreaTreeAddCoach(this.orderType).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.treeList = val.data;
        }
      })
    },
    getBirthDate(e, index) {
      switch (e) {
        case 'theFirstWorkDate': {
          let time = this.updateItem.coachRoster.theFirstWorkDate
          time = this.dateFormat(time)
          this.updateItem.coachRoster.theFirstWorkDate = time
          break;
        }
        case 'entryDate': {
          let time1 = this.updateItem.coachRoster.entryDate
          time1 = this.dateFormat(time1)
          this.updateItem.coachRoster.entryDate = time1
          break;
        }
        case 'contractStartTime': {
          let time2 = this.updateItem.coachRoster.contractStartTime
          time2 = this.dateFormat(time2)
          this.updateItem.coachRoster.contractStartTime = time2
          break;
        }
        case 'StartTime': {
          let time2 = this.contract.contractStartTime
          time2 = this.dateFormat(time2)
          this.contract.contractStartTime = time2
          break;
        }
        case 'EndTime': {
          let time2 = this.contract.contractEndTime
          time2 = this.dateFormat(time2)
          this.contract.contractEndTime = time2
          break;
        }
        case 'contractEndTime': {
          let time3 = this.updateItem.coachRoster.contractEndTime
          time3 = this.dateFormat(time3)
          this.updateItem.coachRoster.contractEndTime = time3
          break;
        }
        case 'birthday': {
          let time5 = this.updateItem.coachPersonageDetails.birthday
          time5 = this.dateFormat(time5)
          this.updateItem.coachPersonageDetails.birthday = time5
          break;
        }
        default: {
          let time4 = this.updateItem.coachEducationList[index].graduateTime
          time4 = this.dateFormat(time4)
          this.updateItem.coachEducationList[index].graduateTime = time4
          break;
        }
      }
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    async getList() {
      console.log("开始加载字典值")
      selectDictByType('signContractFirstParty').then(res => {
        this.CompanyList = res.data
      })
      console.log("开始加载可视校区数据")
      listAreaTreeAddCoach().then(val => {
        if (val.code == 200) {
          console.log("开始加载修改教练的所属校区1");
          console.info(val.data);

          this.getTypeList(val.data);
          this.treeList = val.data;

          console.log("开始加载修改教练的所属校区2");
          console.info(this.treeList);
        }
      })
      listXcxDept().then(res => {
        this.deptList = this.getTypeList(res.data);
      })
      roles().then(res => {
        if (res.code == 200) {
          this.roles = res.data
        } else {
          this.$message.error("获取角色数据失败");
        }
      })
      //业务类型
      selectDictByType('businessType').then(res => {
        this.businessTypeList = res.data
      })
      //民族
      selectDictByType('nation').then(res => {
        this.nationList = res.data
      })
      //工作地点
      selectDictByType('workplace').then(res => {
        this.workplaceList = res.data
      })
      selectDictByType('educationRank').then(res => {
        this.educationRankList = res.data
      })
      selectDictByType('staffType').then(res => {
        this.staffTypeList = res.data
      })
      selectDictByType('contractType').then(res => {
        this.contractTypeList = res.data
      })
    }
  }

}


</script>
<style scoped>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.el-dialog__body {
  height: 350px !important;
}

.addWorkNumber {
  padding: 0 5px;
  border: 1px solid black;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: 10px;
  text-align: center;
  font-size: large;
  cursor: pointer;
}
</style>
