<template>
  <div style="padding: 20px">
    <div>
      <!--      筛选条件区-->
      <div class="handle-box" style="display:flex;flex-wrap: wrap;">
        <!--新增按钮-->
        <el-button type="primary" class="handle-del margin10" @click="addVisible = true">新增教练</el-button>
        <!--        区域筛选组件-->
        <div class="margin10">
          <select-area @areaListId="getAreaListId"/>
        </div>
        <!--        角色筛选-->
        <el-select v-model="query.roleId" placeholder="角色" class="handle-select margin10">
          <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name"></el-option>
        </el-select>
        <!--        手机号筛选-->
        <el-input style="width: 200px" v-model="query.keyword" placeholder="教练名或者手机号"
                  class="handle-input margin10"></el-input>
        <!--        入职日期筛选-->
        <div class="margin10">
          <el-date-picker
              style="width: 260px;margin-right: 10px;"
              v-model="v3"
              type="daterange"
              range-separator="至"
              @change="disposeTime"
              start-placeholder="入职时间"
              end-placeholder="入职时间"
              :clearable="false">
          </el-date-picker>
        </div>
        <!--        转正日期筛选-->
        <div class="margin10">
          <el-date-picker
              style="width: 260px;margin-right: 10px;"
              v-model="v1"
              type="daterange"
              range-separator="至"
              @change="disposeTime"
              start-placeholder="转正时间"
              end-placeholder="转正时间"
              :clearable="false"
          >
          </el-date-picker>
        </div>
        <!--        离职日期筛选-->
        <div class="margin10">
          <el-date-picker
              style="width: 260px;margin-right: 10px;"
              v-model="v2"
              type="daterange"
              range-separator="至"
              @change="disposeTime"
              start-placeholder="离职时间"
              end-placeholder="离职时间"
              :clearable="false"
          >
          </el-date-picker>
        </div>
        <!--        教练状态筛选-->
        <el-select style="width: 100px" class="margin10" placeholder="状态" v-model="query.state">
          <el-option label="全部" value=""></el-option>
          <el-option label="开启" value="开启"></el-option>
          <el-option label="禁用" value="禁用"></el-option>
        </el-select>
        <!--        搜索按钮-->
        <el-button type="primary" @click="handleSearch" class="margin10">搜索</el-button>
        <el-button type="info" @click="reset" class="margin10">重置</el-button>

        <!--        导出按钮弹框-->
        <el-popconfirm
            title="确定导出吗？"
            @confirm="exportCoachBtn"
        >
          <template #reference>
            <el-button type="primary" class="margin10">导出</el-button>
          </template>
        </el-popconfirm>
        <!-- <el-popconfirm
          title="确定导出吗花名册吗？"
          @confirm="exportCoachRoster"
         >
          <template #reference>
            <el-button type="primary" class="margin10">导出花名册</el-button>
            </template>
        </el-popconfirm> -->
      </div>

      <!--      列表区-->
      <el-table
          v-loading="tableLogin"
          :data="tableData"
          height="590px"
          border
          class="table"
          ref="multipleTable"
          :row-style="{height:9+'px'}"
          :cell-style="{padding:8+'px'}"
          header-cell-class-name="table-header"
      >
        <el-table-column label="ID" align="center" show-overflow-tooltip prop="coachId"/>
        <el-table-column label="工号" align="center" show-overflow-tooltip prop="workNumber"/>
        <el-table-column label="教练姓名" align="center" show-overflow-tooltip prop="coachName"/>
        <el-table-column label="教练电话" align="center" show-overflow-tooltip prop="coachPhone"/>
        <el-table-column prop="deptName" label="所属部门" show-overflow-tooltip></el-table-column>
        <el-table-column show-overflow-tooltip="true" label="所属校区">
          <template #default="scope">
            {{ scope.row.campusName != null ? scope.row.campusName + '-' + scope.row.flagName : '' }}
          </template>
        </el-table-column>
        <el-table-column label="角色" >
          <template #default="scope">
            <el-select placeholder="角色" v-model="scope.row.roleIdList">
              <el-option v-for="(item,index) in scope.row.roles" :key="index" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            label="状态"
        >
          <template #default="scope">
            <el-tooltip :content="scope.row.state=='开启'? '关闭后此教练将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="setState(scope.row.coachId,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="禁用"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            label="师资背景状态"
        >
          <template #default="scope">
            <el-tooltip :content="scope.row.isVisible=='1'? '关闭后小程序师资背景将关闭':'确定要开启吗'" placement="top">
              <el-switch
                  @click="changeState(scope.row.coachId,scope.row.isVisible)"
                  v-model="scope.row.isVisible"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="!scope.row.particulars"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="addDate" label="添加时间"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template #default="scope">
            <el-button text type="primary" style="width: 14px;" size="small"
                       @click="detailDialog = true, detailItem = scope.row">详情
            </el-button>
            <el-popover placement="left" :width="251" trigger="click">
              <div class="space-around" style="margin: 5px 0 10px 0">
                <el-button
                    type="text"
                    @click="handleEdit(scope.row)"
                >编辑
                </el-button>
                <el-button
                    type="text"
                    @click="handleEdits( scope.row)"
                >证书
                </el-button>
                <el-button
                    type="text"
                    @click="teachers(scope.row)"
                >师资背景
                </el-button>
              </div>
              <template #reference>
                <el-button size="small" type="primary" style="width: 14px;" text>操作</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>


      </el-table>

      <!--      element分页组件-->
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 证书弹出框 -->
    <el-dialog title="证书列表" v-model="certificate" width="60%">
      <el-button type="primary" class="handle-del mr10" @click="certificate = false,addcertificate=true">新增证书
      </el-button>
      <el-table
          style="margin-top: 50px"
          :data="tableDateTwo"
          border
          class="table"
          ref="multipleTable"
          :row-style="{height:10+'px'}"
          :cell-style="{padding:5+'px'}"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="name" label="证书名字" width="160px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160px"></el-table-column>
        <el-table-column
            label="图片"
            width="100">
          <template v-slot="scope">
            <el-image class="code" v-if="scope.row.picture !==null"
                      hide-on-click-modal
                      :src="scope.row.picture"
                      :preview-src-list="scope.row.picture"
            >

              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" width="110px">
          <template #default="scope">
            <el-tooltip :content="scope.row.state=='开启'? '关闭后此教练将无法使用':'确定要开启吗'" placement="top">
              <el-switch
                  @click="changeStates(scope.row.id,scope.row.state)"
                  v-model="scope.row.state"
                  active-value="开启"
                  inactive-value="禁用"
              >
              </el-switch>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="110px">
          <template #default="scope">
            <el-tag>
              {{ scope.row.type == 1 ? '标签' : '证书' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="zsEdit(scope.$index, scope.row)"
            >证书编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-dialog>
    <!-- 添加证书弹出框 -->
    <el-dialog title="添加修改证书" v-model="addcertificate" width="50%">
      <el-form :rules="addzsRules">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="name" label="证书-标签">
              <el-input style="margin-right:20px" v-model="certificates.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="state" label="状态">
              <el-select class="mr10" placeholder="状态" v-model="certificates.state">
                <el-option label="开启" value="开启"></el-option>
                <el-option label="关闭" value="关闭"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="type" label="类型">
              <el-select class="mr10" placeholder="类型" v-model="certificates.type">
                <el-option label="标签" value="1"></el-option>
                <el-option label="证书" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="证书照片">
              <el-upload
                  class="upload"
                  :http-request="uploadHttp"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="handleRemove"
                  :class="{uoloadSty:thisImage==null ? true:false,disUoloadSty:thisImage!=null ? true:false}"
                  :limit="1"
                  list-type="picture-card">
                <el-button icon="Plus" text/>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="editLogin" @click="addcertificate = false">取 消</el-button>
          <el-button :disabled="editLogin" type="primary" @click="addzs()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 师资背景弹出框 -->
    <el-dialog title="师资背景" v-model="teachersdialog" width="900px">
      <el-form :rules="teachersRules" :model="tearcher">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="introduction" label="个人简介">
              <textarea class="introduction" style="margin-right:20px" v-model="tearcher.introduction"></textarea>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="title" label="标题">
              <el-input style="margin-right:20px" v-model="tearcher.title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="starNum" label="评价星级">
              <el-input style="margin-right:20px" v-model.number="tearcher.starNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="sort" label="排序">
              <el-input style="margin-right:20px" v-model.number="tearcher.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item prop="classNum" label="预约数">
              <el-input style="margin-right:20px" v-model.number="tearcher.classNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="praiseNum" label="好评数">
              <el-input style="margin-right:20px" v-model.number="tearcher.praiseNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="praiseRate" label="好评率">
              <el-input style="margin-right:20px" v-model.number="tearcher.praiseRate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="editLogin" @click="teachersdialog = false">取 消</el-button>
          <el-button :disabled="editLogin" type="primary" @click="teacherBtn()">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改教练信息" v-loading="editLogin" v-model="editVisible" width="1300px" @close="closeDialog"
               @open="openDialog">
      <div style="height:550px">
        <coach-update :editItem="editItem" ref="uploadCoach"/>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button :disabled="editLogin" @click="editVisible = false">取 消</el-button>
          <el-button :disabled="editLogin" type="primary"
                     @click="saveEdit()">{{ editLogin == true ? '请稍等..' : '确 定' }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 新增弹出框框 -->
    <el-dialog title="教练信息录入" v-loading="addLogin" v-model="addVisible" width="900px" @close="closeDialog">
      <el-form v-loading="addLogin" ref="addRules" :rules="addRules" :model="addItem"
               style="padding: 20px 40px 20px 20px" label-width="90px">
        <el-row>
          <el-col :span="8">
            <el-form-item prop="coachName" label="教练姓名">
              <el-input v-model="addItem.coachName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="coachPhone" label="教练电话">
              <el-input v-model="addItem.coachPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="workNumber" label="工号">
              <el-input v-model="addItem.workNumber"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <div style="display: flex">
          <div>
            <div style="display: flex">
              <el-form-item style="width: 268px" label="教练类型">
                <el-select placeholder="选择类型" @change="changeStudentType" v-model="orderType">
                  <div v-if="sysStudentType==3">
                    <el-option label="全部"></el-option>
                    <el-option label="中招体育" value="1"></el-option>
                    <el-option label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>
                  <div v-if="sysStudentType!=3">
                    <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
                    <el-option v-else label="少年" value="2"></el-option>
                    <el-option label="高考体育" value="4"></el-option>
                  </div>
                </el-select>
              </el-form-item>
              <el-form-item prop="campusId" style="width: 266px" label="所属校区">
                <el-cascader
                    v-model="addItem.campusId"
                    placeholder="请选择校区"
                    :options="treeList"
                    filterable
                    :props="{value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>

              <el-form-item prop="roles" label="角色">
                <el-select
                    v-model="addItem.roles"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip
                    placeholder="请选择"
                    style="width: 160px">
                  <el-option v-for="item in roles" :value="item.id" :key="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item prop="areaList" label="可视区域">
                <el-cascader
                    v-model="addItem.areaList"
                    style="width: 177px"
                    placeholder="请选择区域"
                    :options="treeList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    clearable
                    :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
              </el-form-item>
              <el-form-item prop="deptId" label="所属部门">
                <el-cascader
                    v-model="addItem.deptId"
                    style="width: 177px"
                    placeholder="请选择部门"
                    :props="{
                         value: 'id',
                         label: 'name',
                         children: 'children',
                         checkStrictly: true}"
                    :options="deptList"/>
              </el-form-item>
              <el-form-item label="教练头像">
                <el-upload
                    ref='upload'
                    class="upload"
                    :http-request="uploadCocah"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove"
                    :class="{uoloadSty:thisImage==null ? true:false,disUoloadSty:thisImage!=null ? true:false}"
                    :limit="1"
                    list-type="picture-card">
                  <el-button icon="Plus" text/>
                </el-upload>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer footer">
          <el-button :disabled="addLogin" @click="addVisible = false">取 消</el-button>
          <el-button :disabled="addLogin" type="primary"
                     @click="saveAdd()">{{ addLogin == true ? '请稍等..' : '确 定' }}</el-button>
        </span>
      </template>
    </el-dialog>

<!--    教练个人信息详情 组件-->
    <el-dialog title="教练个人信息" v-model="detailDialog" width="1300px">
      <coach-detail :item="detailItem"/>
    </el-dialog>
  </div>
</template>
<script>
import {listXcxDept} from "@/api/dept";
import {
  pageList,
  edit,
  editSate, creationCoach,
  roles, changeFaculty, getcertificate,
  certificateState, addcertificate, getOramend,
  exportCoach,
  exportCoachRosterExcel
} from "@/api/coach";
import {uploadImage} from '@/api/helpManual'
import selectArea from "../../components/SelectArea";
import {listAreaTreeAddCoach} from "@/api/area.js";
import coachUpdate from "./components/CoachUpdate";
import coachDetail from "./components/CoachDetail";

export default {
  components: {
    selectArea, coachUpdate,
    coachDetail
  },
  name: "Coach",
  data() {
    return {
      v1: [],
      v2: [],
      v3: [],
      detailDialog: false,
      detailItem: {},
      certificates: {
        name: '',
        state: '',
        type: '',
        coachId: '',
        picture: ''
      },
      addcertificate: false,
      addLogin: false,
      editLogin: false,
      orderType: null,
      sysStudentType: localStorage.getItem('studentType'),
      studentType: null,
      treeList: [],
      deptList: [],
      tableLogin: false,
      thisImage: null,
      query: {
        areaList: [],
        type: '',
        keyword: "",
        pageIndex: 1,
        pageSize: 11,
        state: "",
        dimissionStartDate: '', //离职日期 ---结束
        dimissionEndDate: '',//离职日期 ---开始
        officialEndDate: '',//入职日期 --- 结束
        officialStartDate: '',//入职日期 --- 开始
        entryStartDate: '',
        entryEndDate: ''
      },
      teachersRules: {
        praiseRate: [
          {required: true, message: '好评率是必须的'},
          {type: 'number', message: '好评率必须为数值'},
        ],
        praiseNum: [
          {required: true, message: '好评数是必须的'},
          {type: 'number', message: '好评数必须为数值'},
        ],
        classNum: [
          {required: true, message: '预约数是必须的'},
          {type: 'number', message: '预约数必须为数值'},
        ],
        starNum: [
          {required: true, message: '评价星级是必须的'},
          {type: 'number', message: '评价星级必须为数值'},
        ],
        sort: [
          {required: true, message: '排序是必须的'},
          {type: 'number', message: '排序必须为数值'},
        ]
      },
      rules: {
        coachname: [
          {required: true, message: '请输入教练名字', trigger: 'blur'},
        ],
        coachphone: [
          {required: true, message: '请输入教练电话', trigger: 'blur'},
        ],
        regionid: [
          {required: true, message: '请选择城市', trigger: 'change'},
        ],
        roleId: [
          {required: true, message: '请选择教练角色', trigger: 'change'}
        ],

      },
      tableData: [],
      tableDateTwo: [],
      addVisible: false,
      addItem: {
        workNumber: '',
        coachName: "",
        coachPhone: "",
        roles: [],
        areaList: [],
        campusId: null,
        deptId: '',
        portrait: ''
      },
      addRules: {
        coachName: [
          {required: true, message: '请输入教练名字', trigger: 'blur'},
        ],
        coachPhone: [
          {required: true, message: '请输入教练电话', trigger: 'blur'},
        ],
        campusId: [
          {required: true, message: '请选择所属校区', trigger: 'blur'},
        ],
        areaList: [
          {required: true, message: '请选择可视区域', trigger: 'change'},
        ],
        roles: [
          {required: true, message: '请选择教练角色', trigger: 'change'}
        ],
        deptId: [
          {required: true, message: '请选择所属部门', trigger: 'change'}
        ]
      },
      addzsRules: {
        type: [
          {required: true, message: '请选择状态', trigger: 'blur'},
        ],
        state: [
          {required: true, message: '请选择标签', trigger: 'blur'},
        ],
      },
      editVisible: false,
      editItem: {},
      certificate: false,
      pageTotal: 0,
      token: '',
      tearcher: {
        classNum: null,
        coachId: '',
        grade: null,
        id: null,
        introduction: '',
        praiseNum: null,
        starNum: null,
        praiseRate: null,
        title: '',
        sort: ''
      },
      teachersdialog: false
    };
  },
  provide() {
    return {editContract: this.editContract}
  },
  created() {
    this.token = localStorage.getItem('token')
    if (localStorage.getItem('ms_username') == 'admin') {
      this.isAdmin = true
    }
    this.getData();
    this.getAreaList();
    this.getDept();
    roles().then(res => {
      if (res.code == 200) {
        this.roles = res.data
      } else {
        this.$message.error("获取角色数据失败");
      }
    })

  },
  methods: {
    editContract() {
      this.editVisible = false
      this.getData()
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    //处理入职离职时间
    disposeTime() {
      if (this.v1.length >= 2) {
        this.query.officialStartDate = this.dateFormat(this.v1[0]);
        this.query.officialEndDate = this.dateFormat(this.v1[1]);
      }
      if (this.v2.length >= 2) {
        this.query.dimissionEndDate = this.dateFormat(this.v2[1]);
        this.query.dimissionStartDate = this.dateFormat(this.v2[0]);
      }
      if (this.v3.length >= 2) {
        this.query.entryStartDate = this.dateFormat(this.v3[0]);
        this.query.entryEndDate = this.dateFormat(this.v3[1]);
      }
    },
    //导出花名册
    exportCoachRoster() {
      exportCoachRosterExcel(this.query).then(res => {
        let blob = new Blob([res], {type: "application/vnd.ms-excel"});
        console.log(blob);
        let objectUrl = URL.createObjectURL(blob);
        console.log(objectUrl);
        const link = document.createElement('a');//我们用模拟q标签点击事件
        const fname = '教练花名册'; //下载文件的名字
        link.href = objectUrl;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        /**
         * 在这期间，我们可以做自己界面的反应逻辑
         **/
        link.click();//点击
        document.body.removeChild(link); // 下载完成移除元素
        window.URL.revokeObjectURL(URL); // 释放掉blob对象
      })
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.uploadCoach.activeName = 'first';
      })
    },
    exportCoachBtn() {
      exportCoach(this.query)
    },
    teacherBtn() {
      getOramend(this.tearcher).then(res => {
        if (res.code == 200) {
          this.getData()
        }
        this.teachersdialog = false
      })
    },
    //操作--师资背景
    teachers(row) {
      this.teachersdialog = true
      if (row.particulars == null) {
        this.tearcher = {
          classNum: '',
          coachId: row.coachId,
          grade: null,
          id: null,
          introduction: '',
          praiseNum: '',
          starNum: '',
          praiseRate: '',
          title: '',
          sort: ''
        }
      } else {
        this.tearcher = row.particulars
      }
    },

    //证书编辑
    zsEdit(a, b) {
      this.certificate = false
      this.addcertificate = true
      this.certificates = b
    },
    //添加证书
    addzs() {
      addcertificate(this.certificates).then(res => {
        console.log(res);
      })
      this.certificates = {}
      this.addcertificate = false
    },
    /**
     * 选择学员类型
     */
    changeStudentType(val) {
      this.addItem.areaList = [];
      this.editItem.areaList = [];
      this.getAreaList(val);
    },
    /**
     * 搜索选择区域
     */
    getAreaListId(val) {
      this.query.areaList = val;
    },
    getDept() {
      listXcxDept().then(res => {
        this.deptList = this.getTypeList(res.data);
      })
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    getAreaList(typeId) {
      listAreaTreeAddCoach(typeId).then(val => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.treeList = val.data;
        }
      })
    },
    // 获取数据
    getData() {
      this.tableLogin = true;
      this.disposeTime()
      pageList(this.query).then(res => {
        this.tableLogin = false;
        if (res.code == 200) {
          this.tableData = [];
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }).catch(() => {
      })
    },

    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex = 1
      this.getData();
    },
    // 编辑操作
    handleEdit(row) {
      console.log("修改教练信息!!")
      console.info(row);

      this.editItem = row;
      this.editVisible = true;
    },
    //证书编辑
    async handleEdits(row) {
      // console.log(index,row);
      this.certificates.coachId = row.coachId
      this.certificate = true
      // this.addcertificate = true
      await getcertificate(row.coachId).then(res => {
        if (res.code == 200) {
          this.tableDateTwo = res.data
        }

      })
    },
    //取消编辑
    qx() {
      this.editVisible = false;
      this.getData()
      this.$message.info(`您取消了修改`);
    },
    /**
     * 编辑教练信息
     */
    saveEdit() {
      this.$refs.uploadCoach.$refs['firstAddRules'].validate((valid) => {
        if (valid) {
          if (Array.isArray(this.$refs.uploadCoach.updateItem.deptId)) {
            this.$refs.uploadCoach.updateItem.deptId = this.$refs.uploadCoach.updateItem.deptId[this.$refs.uploadCoach.updateItem.deptId.length - 1]
          }
          if (this.$refs.uploadCoach.updateItem.areaList&&this.$refs.uploadCoach.updateItem.areaList.length >= 1) {
            let areaLists = [];
            this.$refs.uploadCoach.updateItem.areaList.forEach(function (item) {
              if (/^[0-9]+$/.test(item)) {
                areaLists.push(item)
              } else {
                areaLists.push(...item)
              }
            })
            this.$refs.uploadCoach.updateItem.areaList = areaLists;
          }
          if (this.$refs.uploadCoach.updateItem.lookDeptIDList && this.$refs.uploadCoach.updateItem.lookDeptIDList.length >= 1) {
            let areaLists = [];
            let  lookDeptIDList = this.$refs.uploadCoach.updateItem.lookDeptIDList
            areaLists = lookDeptIDList.flat()
            lookDeptIDList.forEach(function (item) {
              if(!Array.isArray(item)){ 
                areaLists.push(item)
              }
            })
            areaLists = [...new Set(areaLists)]
            this.$refs.uploadCoach.updateItem.lookDeptIDList = areaLists;
          }
          if (this.$refs.uploadCoach.updateItem.adminAreaList&&this.$refs.uploadCoach.updateItem.adminAreaList.length >= 1) {
            let areaLists = [];
            this.$refs.uploadCoach.updateItem.adminAreaList.forEach(function (item) {
              if (/^[0-9]+$/.test(item)) {
                areaLists.push(item)
              } else {
                areaLists.push(...item)
              }
            })
            this.$refs.uploadCoach.updateItem.adminAreaList = areaLists;
          }

          if (this.$refs.uploadCoach.updateItem.campusId&&this.$refs.uploadCoach.updateItem.campusId.length > 1) {
            this.$refs.uploadCoach.updateItem.campusId = this.$refs.uploadCoach.updateItem.campusId[this.$refs.uploadCoach.updateItem.campusId.length - 1];
          }
          if (this.$refs.uploadCoach.updateItem.deptId&&this.$refs.uploadCoach.updateItem.deptId.length > 1) {
            this.$refs.uploadCoach.updateItem.deptId = this.$refs.uploadCoach.updateItem.deptId[this.$refs.uploadCoach.updateItem.deptId.length - 1];
          }
          this.$refs.uploadCoach.addConversionType()
          edit(this.$refs.uploadCoach.updateItem).then(res => {
            this.editLogin = false;
            this.editVisible = false;
            if (res.code == 200) {
              if (res.success) {
                this.$message.success(`修改成功！`);
              } else {
                this.$message.error(`修改失败`);
              }
            } else {
              this.$message.error(`修改失败`);
            }
            this.getData()
          })
        } else {
          this.$message.error('请正确填写教练信息')
          this.$refs.uploadCoach.activeName = 'first';
          return false;
        }
      })
    },
    /**
     * 新增教练
     */
    saveAdd() {
      this.$refs['addRules'].validate((valid) => {
        if (valid) {
          this.addLogin = true;
          this.addItem.campusId = this.addItem.campusId[this.addItem.campusId.length - 1];
          this.addItem.deptId = this.addItem.deptId[this.addItem.deptId.length - 1];
          let areaListId = [];
          this.addItem.areaList.forEach(function (item) {
            areaListId.push(...item)
          })
          this.addItem.areaList = areaListId;
          creationCoach(this.addItem).then(res => {
            this.addLogin = false;
            this.addVisible = false;
            if (res.code == 200) {
              if (res.success) {
                this.getData()
                this.$message.success(`新增成功`);
                this.addItem = {}
              } else {
                this.$message.error(`新增教练: 失败`);
                this.addItem = {}
              }
            } else {
              this.$message.error(`新增失败` + res.message);
              this.addItem = {}
            }

          })
        } else {
          return false;
        }
      })
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    /**
     * 修改教练状态
     */
    setState(id, state) {
      editSate(id, state).then(res => {
        if (res.code == 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })
    },
    //修改教练是否打开师资背景
    changeState(id, state) {
      {
        changeFaculty(id, state).then(res => {
          if (res.code == 200) {
            this.$message.success(`操作成功`);
          } else {
            this.$message.error(`操作失败`);
          }
        })
      }
    },
    //修改证书状态开启关闭
    changeStates(id, state) {
      certificateState(id, state).then(res => {
        if (res.code == 200) {
          this.$message.success(`操作成功`);
        } else {
          this.$message.error(`操作失败`);
        }
      })
    },
    // 重置
    reset() {
      this.query.regionId = ""
      this.query.campusId = ""
      this.query.roleId = ""
      this.query.keyword = ""
      this.query.pageIndex = 1
      this.getData()
    },

    uploadCocah(file) {
      this.thisImage = file.file
      const data = new FormData()
      data.append(
          'multipartFile', file.file
      )
      data.append(
          'flag', 'coach'
      )
      data.append(
          'fileName', file.file.name
      )
      uploadImage(data).then(res => {
        // console.log(res);
        this.addItem.portrait = res.data

        // console.log(this.certificates.picture);
      })
    },
    /**
     * 保存图片
     */
    uploadHttp({file}) {
      this.thisImage = file;
      const data = new FormData()
      data.append(
          'multipartFile', file
      )
      data.append(
          'flag', 'coachlabel'
      )
      data.append(
          'fileName', file.name
      )
      uploadImage(data).then(res => {
        // console.log(res);
        this.certificates.picture = res.data
        // console.log(this.certificates.picture);
      })
    },
    /**
     * 删除图片
     */
    handleRemove() {
      this.thisImage = null;
    },
    /**
     * 图片限制
     */
    beforeAvatarUpload(file) {
      const isJPG = file.name.split('.')[1] === 'jpg';
      const isPNG = file.name.split('.')[1] === 'png';
      const one = file.limit === 1;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (one > 2) {
        this.$message.error('上传图片只能1张!');
      }
      return (isJPG || isPNG);
    },
  }
}

</script>
<style scoped>
.space-around {
  display: flex;
  justify-content: space-around;
}

.uoloadSty /deep/ .el-upload--picture-card {
  margin-top: -40px;
}

.disUoloadSty /deep/ .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.margin10 {
  margin: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .avatar {
  width: 80px;
  height: 80px;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}

.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}

.uoloadSty[data-v-3b7bf81b] .el-upload--picture-card {
  margin-top: 0px !important;
}

.el-form-item__content {
  margin-right: 20px;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px
}

.introduction {
  width: 700px;
  height: 100px;
}

.footer {
  margin-top: 20px;
}

</style>
